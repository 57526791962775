// Gray Scale
$color--gray__dark: #333333;
$color--gray__medium: #575757;
$color--white: #ffffff;

// Color Palette
$color--blue__dark: rgba(14, 28, 72, 0.85);
$color--blue__medium: #f3fdff;
$color--blue__light: #e8f2fd;
$color--blue__ultra-light: #fbfcff;
$color--yellow__light: #f4f4f2;
$color--pink: #f76464;

// No Color is a color
$transparent: rgba(0, 0, 0, 0);

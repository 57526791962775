// Spacings

// Viewports
$viewport-xsmall: 576px;
$viewport-small: 768px;
$viewport-medium: 1024px;
$viewport-large: 1280px;
$viewport-infinite: 999999px;

// Typography Variables
$font-weight--bold: 800;
$font-weight--regular: 400;
$font-name--eb-garamond: 'EB Garamond', serif;
$font-name--open-sans: 'Open Sans', san-serif;
$font-name--noto-serif-jp: 'Noto Serif JP', serif;
$font-name--noto-sans-jp: 'Noto Sans JP', sans-serif;

// Max Width
$composition--max-width: 1320px;
$composition--outer-gutter: 3rem;
$composition--outer-gutter--mobile: 1.5rem;
// Import Dependencies
@import '../params/_colors';
@import '../params/_variables';

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  white-space: pre-wrap;

  &.inverse {
    color: $color--white;
  }

  &.all-caps {
    text-transform: uppercase;
  }
}

h1 {
  color: $color--gray__dark;
  font-size: 64px;
  font-family: $font-name--eb-garamond;
  font-weight: $font-weight--bold;
}

h2 {
  color: $color--gray__dark;
  font-size: 44px;
  font-family: $font-name--eb-garamond;
  font-weight: $font-weight--bold;
}

h3 {
  color: $color--gray__dark;
  font-size: 36px;
  font-family: $font-name--eb-garamond;
  font-weight: $font-weight--bold;
}

h4 {
  color: $color--gray__dark;
  font-size: 32px;
  font-family: $font-name--eb-garamond;
  font-weight: $font-weight--bold;
}

h5 {
  color: $color--gray__dark;
  font-size: 28px;
  font-family: $font-name--eb-garamond;
  font-weight: $font-weight--bold;
}

h6 {
  color: $color--gray__dark;
  font-size: 24px;
  font-family: $font-name--eb-garamond;
  font-weight: $font-weight--bold;
}

p {
  color: $color--gray__medium;
  font-size: 16px;
  line-height: 24px;
  font-family: $font-name--open-sans;
  font-weight: $font-weight--regular;

  &.small {
    font-size: 12px;
  }
}

a {
  text-decoration: none;
  color: $color--pink;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;

  &.external {
    text-decoration: underline;
  }
}

br {
  line-height: 150%;
}

.bold {
  font-weight: bold;
}

span {
  &.italic {
    font-style: italic;
  }
}

@media only screen and (max-width: $viewport-small) {
  h3 {
    font-size: 28px;
  }

  h4 {
    font-size: 26px;
  }

  h6 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
    line-height: 22px;
  }
}
@import '../../styles/params/index';

.app {
  overflow-x: hidden;
  transition: opacity 0.25s ease-out;

  .app-wrapper--scrollable,
  .app-wrapper--pre-scrollable,
  .app-wrapper--post-scrollable {
    position: relative;
    width: 100vw;
  }

  .app-wrapper--pre-scrollable {
    &.vision {
      position: fixed;
      z-index: 120;
    }
  }

  .app-wrapper--post-scrollable  {
    &.vision {
      position: fixed;
      z-index: 110;
    }
  }

  &.japanese {
    h1,
    h2,
    h3, 
    h4,
    h5,
    h6 {
      &.t {
        font-family: $font-name--noto-serif-jp;
        font-weight: $font-weight--bold;
      }
    }
  }

  &.not-ready {
    opacity: 0.3;
  }
}
